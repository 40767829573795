import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_SELECT } from "../../../constants";
import { isValueEmpty } from "../../../../Layouts/form/utils";
import { getGameCodes } from "../../../game-codes/game-codes-saga";
const stageGameCodeOptions = (values) => {
  return values.map((it) => {
    return {
      label: it.name,
      value: it.name,
    };
  });
};
class GameTransactionsService {
  async getFilterFields({ brand, partner, platform, currency, category }) {
    const filters = [
      {
        name: "creationPeriod",
        label: "label.individualplayer.gameTransactions.creationPeriod",
        type: "period",
        placeholder: "label.placeholders.date",
      },
      {
        name: "openGameSession",
        label: "label.individualplayer.gameTransactions.openGameSession",
        type: "checkbox",
      },
    ];

    let urlGameCode = `/gov/api/rest/v3/game-codes-allstatuses?brand=${brand}&partner=${partner}&platform=${platform}&currency=${currency}&category=${
      category ? category : ""
    }`;
    let responseGameCode = await axios.get(urlGameCode);
    let gameCode = "";

    if (responseGameCode && responseGameCode.data) {
      gameCode = {
        name: "gameNames",
        label: "label.individualplayer.gameTransactions.gameNames",
        type: CRITERIA_TYPE_SELECT,
        multiple: true,
        optional: true,
        placeholder: "label.placeholders.select",
        options: stageGameCodeOptions(responseGameCode.data),
      };

      filters.unshift(gameCode);
    }

    let urlPlatform = "/gov/api/rest/v2/game-platforms";
    const responsePlatform = await axios.get(urlPlatform);
    let gamePlatform = "";
    if (responsePlatform && responsePlatform.data) {
      gamePlatform = {
        name: "gameDomains",
        label: "label.individualplayer.gameTransactions.gameDomains",
        type: CRITERIA_TYPE_SELECT,
        multiple: true,
        placeholder: "label.placeholders.select",
        options: [],
        onChange: (values) => {
          filters.find((it) => it.name === "gameNames").disabled =
            values?.length > 1;

          if (values.length <= 1) {
            responseGameCode = getGameCodes({
              brand,
              partner,
              platform: isValueEmpty(values) ? "*" : values,
              currency,
              category,
            }).then((response) => {
              filters.find(
                (it) => it.name === "gameNames"
              ).options = stageGameCodeOptions(response);
            });
          }
        },
      };
      responsePlatform.data.forEach((it) => {
        if (it.key !== "-9999") {
          gamePlatform["options"].push({
            label: it.value,
            value: it.key,
          });
        }
      });
      filters.unshift(gamePlatform);
    }

    filters.unshift({
      name: "gameSessionId",
      label: "label.individualplayer.gameTransactions.gameSessionId",
    });

    return filters;
  }

  async getGameTransactions(
    contractId,
    brandId,
    partnerId,
    searchTerms,
    page,
    pageSize,
    orderBy
  ) {
    const order =
      orderBy != null
        ? searchUtils.getOrder(orderBy.order) + orderBy.field
        : null;
    const url = `/gov/api/rest/v2/players/${contractId}/game-sessions`;

    const queryParam = searchTerms
      ? searchUtils.getQueryParamWithList(searchTerms, false, false)
      : "";
    const response = await axios.get(url + queryParam, {
      params: {
        page,
        pageSize,
        brandId,
        partnerId,
        order,
      },
    });

    if (response && response.data && response.data.gameSessions) {
      return {
        data: response.data.gameSessions,
        page: page,
        totalCount: response.data.totalItems,
      };
    }
    throw Error("Invalid response for game transactions");
  }

  async getGameTransactionDetails(
    playerId,
    brandId,
    partnerId,
    gameSessionId,
    platformId
  ) {
    const url = `/gov/api/rest/v2/players/${playerId}/game-sessions/${gameSessionId}`;

    const response = await axios.get(url, {
      params: {
        brandId,
        partnerId,
        platformId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for game transaction details");
  }

  async getGameReplay(
    playerId,
    brand,
    partner,
    gameCode,
    gameSessionId,
    roundId,
    languageCode,
    platformId
  ) {
    const url = `/gov/api/rest/v1/game-replay-url/${gameCode}`;

    const response = await axios.get(url, {
      params: {
        brand,
        partner,
        "contract-id": playerId,
        "game-session-id": gameSessionId,
        "round-id": roundId,
        "language-code": languageCode,
        "platform-id": platformId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for game replay");
  }
}

const gameTransactionsService = new GameTransactionsService();
export default gameTransactionsService;
